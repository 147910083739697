<template>
  <div style="width:100%;height:100%;">
    <HeaderRow>
         <template #button>
            <el-popconfirm
                title="确认同步钉钉？"
                confirmButtonText="同步"
                cancelButtonText="取消"
                @confirm="syncAllHandler"
                class="add-btn"
            >
                <template #reference>
                <el-button type="primary" icon="el-icon-refresh">同步钉钉</el-button>
                </template>
            </el-popconfirm>
            <el-popconfirm
                title="确认同步AD？"
                confirmButtonText="同步"
                cancelButtonText="取消"
                @confirm="syncAllAdHandler"
                class="add-btn"
            >
                <template #reference>
                <el-button type="primary" icon="el-icon-refresh">同步AD</el-button>
                </template>
            </el-popconfirm>
        </template>
    </HeaderRow>
    <el-input
      placeholder="输入关键字进行过滤"
      size="small"
      v-model="filterText"
      class="org-input"
    >
    </el-input>
    <el-tree
      :data="treeData"
      node-key="id"
      :default-expanded-keys="[1]"
      :filter-node-method="filterNode"
      ref="orgtree"
      v-loading="treeLoading"
    >
      <template #default="{ node, data }">
        <span class="custom-tree-node">
          <el-avatar
            v-if="node.data.info && node.data.info.type == '员工'"
            :size="20"
            :src="showAvatar(node)"
          >
          </el-avatar>
          <span>&nbsp;{{ node.label }}</span>
          <span
            v-if="node.data.info && node.data.info.type === '员工'"
            style="padding: 0 5px;"
          >
            <i class="el-icon-refresh" @click="syncSigle(node, data)"></i>
          </span>
        </span>
      </template>
    </el-tree>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  syncAll,
  syncAllAd,
  searchOrgTree,
  searchSubOrg,
  syncUserSingle,
  syncDeptSignle,
} from "@/utils/api";
import { ElLoading } from "element-plus";
import HeaderRow from "@/views/Admin/components/HeaderRow";

export default {
  components: {
    HeaderRow
  },
  setup() {
    let treeData = ref([]);
    let opens = ref([1]);
    let filterText = ref("");
    let treeLoading = ref(false);

    function getTree() {
      // let loadingInstance = ElLoading.service({
      //   fullscreen: true,
      //   text: "加载中...",
      // });
      // loadingInstance.close();
      treeLoading.value=true
      searchOrgTree().then((res) => {
        treeData.value = [res.data];
        treeLoading.value=false
      });
    }
    function showAvatar(node) {
      if (node.data.info) {
        if (node.data.info.type == "员工") {
          return node.data.info.avatar
            ? node.data.info.avatar
            : "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png";
        }
      }
      return "";
    }
    // 全量同步
    function syncAllHandler() {
      syncAll().then((res) => {
        window.location.reload();
      });
    }
    // 全量同步AD
    function syncAllAdHandler() {
      console.log("ad");
      syncAllAd().then((res) => {
        window.location.reload();
      });
    }
    // 同步单个节点
    function syncSigle(node, data) {
      if (data.info.type === "部门") {
        syncDeptSignle(data.id).then((res) => {});
      } else if (data.info.type === "员工") {
        syncUserSingle(data.id).then((res) => {});
      }
    }
    return {
      treeData,
      opens,
      getTree,
      showAvatar,
      syncAllHandler,
      syncAllAdHandler,
      syncSigle,
      filterText,
      treeLoading,
      filterNode: function(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
    };
  },
  mounted() {
    this.getTree();
  },
  watch: {
    filterText(val) {
      this.$refs.orgtree.filter(val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/element-ui.scss";
.custom-tree-node {
  display: flex;
  align-items: left;
  justify-content: space-between;
  padding-right: 8px;
  font-size: 18px;
  font-family: SourceRegular;
}

.el-tree-node__content {
  margin: 5px;
}
.el-tree{
  padding-bottom: 20px;
}
::v-deep(.org-input){
  margin-bottom: 10px;
  .el-input__inner{
    height: 50px;
  }
}
</style>
